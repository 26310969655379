import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, PreloadAllModules, Router } from '@angular/router';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppComponent } from './app.component';
import { LoadingSpinnerComponent } from './shared/components/loading/loading-spinner.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthService } from './shared/services/auth.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ErrorHandler } from '@angular/core';
import { CallbackResolver } from './home/shared/resolvers/callback.resolver';
import { LogInterceptor } from './shared/interceptors/log.interceptor';
import { ServerMaintenanceDialogModule } from './shared/components/server-maintenance-dialog/server-maintenance-dialog.module';
import { environment } from '../environments/environment';
import { GraphqlService } from './shared/services/graphql.service';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { AssetInterceptor } from './shared/interceptors/asset.interceptor';
import { MultiPartInterceptor } from './shared/interceptors/multi-part.interceptor';
import { GraphQLInterceptor } from './shared/interceptors/graphql.interceptor';
import * as Sentry from '@sentry/angular';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSnackBarModule } from '@angular/material/snack-bar';

export const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  { path: 'loading', component: LoadingSpinnerComponent },
  {
    path: 'callback',
    resolve: {
      callback: CallbackResolver
    },
    component: LoadingSpinnerComponent
  },
  {
    path: 'transient_login',
    loadChildren: () => import('./transient-login/transient-login.module').then(m => m.TransientLoginModule)
  },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  declarations: [AppComponent, LoadingSpinnerComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    ServerMaintenanceDialogModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot({}),
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top'
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.environment !== 'local',
      registrationStrategy: 'registerWhenStable:5000'
    })
  ],
  providers: [
    AuthService,
    CallbackResolver,
    GraphqlService,
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AssetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MultiPartInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GraphQLInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
