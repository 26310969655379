import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, NavigationError } from '@angular/router';
import { Observable, map } from 'rxjs';
import { PwaService } from './shared/services/pwa.service';
@Component({
  selector: 'app-root',
  template: `
    <loading-spinner *ngIf="loading"></loading-spinner>
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
  loading: boolean = true;
  loading$: Observable<boolean> = this.router.events.pipe(
    map(event => {
      if (event instanceof NavigationStart) {
        return (this.loading = true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        return (this.loading = false);
      }
    })
  );

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private pwaService: PwaService
  ) {
    this.loading$.subscribe();
    this.matIconRegistry.addSvgIcon(
      'sympliSYNC',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/images/sympliSYNC.svg`)
    );
  }
}
